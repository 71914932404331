import React from 'react';
import { Typography, Box, Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import staricon from '@iconify/icons-eva/star-fill';

const Styles = makeStyles((theme) => ({
  root: {
    paddingLeft: '20%',
    paddingRight: '20%',
    marginTop: '3%',
    marginBottom: '7%',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '5%',
      paddingRight: '5%'
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '10%',
      paddingRight: '10%'
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '20%',
      paddingRight: '20%'
    }
  },
  title1: {
    fontSize: '30px',
    fontFamily: 'Helvetica'
  },
  title2: {
    fontSize: '28px',
    fontFamily: 'Helvetica',
    marginBottom: '2%'
  },
  cardcontainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },
  card: {
    color: '#FFF',
    background: 'linear-gradient(180deg, rgba(20,77,91,1) 1%, rgba(3,183,222,1) 43%)',
    padding: '2rem',
    [theme.breakpoints.up('xs')]: {
      padding: '0.6rem'
    },
    [theme.breakpoints.up('md')]: {
      padding: '1rem'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '2rem'
    }
  },
  text: {
    fontSize: '1.2rem',
    fontWeight: '700',
    fontFamily: 'HelveticaMd',
    [theme.breakpoints.up('xs')]: {
      fontSize: '1rem',
      fontWeight: '100'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
      fontWeight: '100'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem',
      fontWeight: '100'
    }
  },
  listitem: {
    marginTop: '6%',
    marginBottom: '6%'
  }
}));

function Beneficios() {
  //estilos
  const classes = Styles();
  //info
  const INFO = [
    '8 de cada 10 solicitudes son aprobadas',
    'Topes financieros hasta de $ 50,000,000 MXN',
    'Agilidad en procesos',
    'Atención personalizada',
    'Honestidad y claridad en gestiones',
    'Sin anticipos'
  ];
  return (
    <>
      <Box className={classes.root}>
        <Box sx={{ marginBottom: '2.4rem' }}>
          <Typography variant="h1" color="#01B8E0" className={classes.title1}>
            LA FINANCIERA
          </Typography>
          <Typography variant="h2" color="#6D6F71" className={classes.title2}>
            MÁS SENCILLA Y COMPLETA DEL MERCADO
          </Typography>
        </Box>
        <Box component="div" className={classes.cardcontainer}>
          <Card className={classes.card}>
            <CardContent>
              <List>
                {INFO.map((item) => {
                  return (
                    <ListItem className={classes.listitem}>
                      <ListItemIcon>
                        <Icon icon={staricon} color="#FFF" width="1.1rem" height="1.1rem" />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="body1" className={classes.text}>
                          {item}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
}

export default Beneficios;
