import React from 'react';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
// components
import Page from '../components/Page';
import { AboutWhat, AboutVision, AboutValores } from '../components/_external-pages/about';
import HeroAliran from 'src/components/atomicDesign/HeroAliran';
import imagew from 'src/components/Images/whoweare.jpg';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11)
  }
}));

// ----------------------------------------------------------------------

export default function About() {
  return (
    <>
      <RootStyle title="Aliran | Quienes Somos?">
        <HeroAliran Titulo="¿Quienes" Descripcion="somos?" BotonTexto="Contactar" Imagen={imagew} />
        <AboutWhat />
        <AboutVision />
        <AboutValores />
        {/*<Divider orientation="vertical" sx={{ my: 10, mx: 'auto', width: 2, height: 40 }} />
      <AboutTeam />
      <AboutTestimonials /> */}
      </RootStyle>
    </>
  );
}
