import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const Styles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    color: '#6D6F71',
    fontFamily: 'HelveticaBoldP',
    fontSize: '37px'
  }
}));

TitlePage.propTypes = {
  title: PropTypes.string.isRequired
};

function TitlePage({ title }) {
  //estilos
  const classes = Styles();
  return (
    <>
      <Typography variant="h2" className={classes.root}>
        {title}
      </Typography>
    </>
  );
}

export default TitlePage;
