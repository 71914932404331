import React from 'react';
// material
import { Box } from '@material-ui/core';
import { experimentalStyled as styled, makeStyles } from '@material-ui/core/styles';
import { ProductPresent } from 'src/components/_external-pages/products';
// components
import Page from '../components/Page';
//imgs
import prod2 from 'src/components/Images/producto2.jpg';
// ----------------------------------------------------------------------

const Styles = makeStyles((theme) => ({
  parraf: {
    marginTop: '1%',
    marginBottom: '2%'
  }
}));

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(11),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11)
  }
}));

// ----------------------------------------------------------------------

export default function ArrendamientoFinanciero() {
  const classes = Styles();
  const AF = {
    title: 'Arrendamiento Puro',
    subtitle: 'Tus Finanzas optimizadas',
    description: (
      <>
        <Box component="p" className={classes.parraf}>
          Al tener un esquema de arrendamiento, éste, está diseñado para personas físicas con actividad empresarial o
          personas morales, con la finalidad de adquirir activos productivos con apertura de opción de compra.
        </Box>
        <Box component="p" className={classes.parraf}>
          Contrato para adquisición de activos fijos donde el arrendador adquiere un bien para otorgarle el uso o goce
          temporal a un arrendatario.
        </Box>
        <Box component="p" className={classes.parraf}>
          Al cual se le obliga en esta modalidad, a pagar periódicamente un precio determinado que se denomina renta. La
          arrendadora es la dueña del bien y tienes la opción una vez finalizado el plazo, de adquirir el bien pagando
          un valor residual o devolverlo a la arrendadora. La renta se considera un gasto operativo, el cual es
          deducible.
        </Box>
      </>
    ),
    img: prod2
  };
  return (
    <>
      <RootStyle title="Aliran | Arrendamineto Financiero">
        <ProductPresent title={AF.title} subtitle={AF.subtitle} description={AF.description} img={AF.img} index={0} />
      </RootStyle>
    </>
  );
}
