import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PATH_PAGE } from 'src/routes/paths';
import IconHover from './IconHover';
import iconocs from 'src/components/Images/icons/iconocs.png';
import iconoaf from 'src/components/Images/icons/iconoaf.png';
import iconocp from 'src/components/Images/icons/iconocp.png';

const Styles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(4),
    borderBottom: '6px solid #F36D21',
    borderTop: '6px solid #F36D21'
  },
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Helvetica',
    color: '#FFF'
  },
  title2: {
    textAlign: 'center',
    fontFamily: 'Helvetica',
    paddingLeft: theme.spacing(2),
    color: '#FFF',
    fontWeight: '300'
  }
}));

function ProductosFinancieros() {
  //estilos
  const classes = Styles();
  //ICONS
  const ICONS = [
    {
      icon: iconocs,
      tittle: 'Crédito',
      des: 'Simple',
      link: PATH_PAGE.productcs
    },
    {
      icon: iconoaf,
      tittle: 'Arrendamiento',
      des: 'financiero',
      link: PATH_PAGE.productaf
    },
    {
      icon: iconocp,
      tittle: 'Crédito',
      des: 'prendario',
      link: PATH_PAGE.productcp
    }
  ];
  return (
    <>
      <Box component="div" className={classes.root}>
        <Box component="div" className={classes.container}>
          <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <Typography variant="h3" className={classes.title}>
              PRODUCTOS
            </Typography>
            <Typography variant="h3" className={classes.title2}>
              FINANCIEROS
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {ICONS.map((icon) => {
              return (
                <Grid item xs={12} md={4} lg={4}>
                  <IconHover
                    info={icon}
                    iconSize={{ width: 170, height: 'auto' }}
                    titleStyles={{ color: '#FFF' }}
                    desStyles={{ color: '#FFF' }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default ProductosFinancieros;
