import React from 'react';
// material
import { Typography, Box } from '@material-ui/core';
import { experimentalStyled as styled, useTheme, makeStyles } from '@material-ui/core/styles';
import HeroAliran from 'src/components/atomicDesign/HeroAliran';
//import VideoDialog from 'src/components/atomicDesign/VideoDialog';
// components
import Page from '../components/Page';
import { Iconos, Pasos, Beneficios } from '../components/_external-pages/landing';
import Videoh from 'src/components/Images/Exitoso.jpg';
//import Imagen from '../components/Images/Exitoso.jpg';

// ----------------------------------------------------------------------

const Styles = makeStyles((theme) => ({
  title1: {
    fontSize: '100px',
    fontFamily: 'HelveticaBoldP',
    [theme.breakpoints.up('xs')]: {
      fontSize: '80px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '100px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '100px'
    }
  },
  title2: {
    fontSize: '100px',
    fontFamily: 'HelveticaBoldP',
    paddingLeft: '18px',
    [theme.breakpoints.up('xs')]: {
      fontSize: '75px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '100px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '100px'
    }
  }
}));

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11)
  }
}));

// ----------------------------------------------------------------------

export default function LandingPage() {
  const theme = useTheme();
  const classes = Styles();
  return (
    <>
      <RootStyle title="Aliran | Inicio" id="move_top">
        {/* <LandingHero /> */}

        {/* <LandingMinimal />
        <LandingHugePackElements />
        <LandingDarkMode />
        <LandingCleanInterfaces />
        <LandingAdvertisement /> */}
        <HeroAliran
          Imagen={Videoh}
          Titulo="Crédito"
          Descripcion={
            <>
              <Typography sx={{ display: 'block' }}>
                <Typography variant="p" color="#FFF" className={classes.title1}>
                  Ágil y
                </Typography>
                <Typography variant="p" color={theme.palette.primary.main} className={classes.title2}>
                  Efectivo
                </Typography>
              </Typography>
            </>
          }
          variant="img"
          BotonTexto="SOLICITAR CRÉDITO"
        />
        <Box component="div" sx={{ margin: '15%' }} />
        <Iconos />
        <Pasos />
        <Beneficios />
        {/*<Soluciones />*/}
      </RootStyle>
    </>
  );
}
