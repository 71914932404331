import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Dialog,
  TextField,
  DialogContent,
  DialogActions,
  DialogContentText,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Grid
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { firestore } from 'src/contexts/FirebaseContext';
import DialogAnalisis from '../DialogAnalisis';
import toDate from 'date-fns/esm/fp/toDate/index.js';
import PropTypes from 'prop-types';
import Teclado from './Teclado';

// ----------------------------------------------------------------------

const steps = ['ELIJE MONTO Y PLAZO', 'INGRESA TUS DATOS', 'CONFIRMAR SOLICITUD'];

FormAltaCredito.propTypes = {
  textbutton: PropTypes.string,
  fromstart: PropTypes.bool
};

const Styles = makeStyles((theme) => ({
  root: {},
  cont: {
    padding: theme.spacing(1)
  }
}));

function FormAltaCredito({ textbutton, fromstart = false, sx, className }) {
  //estilos
  const classes = Styles();
  const theme = useTheme();
  //datos de entrada
  const [interes, setInteres] = useState();
  const [enganche, setEnganche] = useState();
  const [activeStep, setActiveSpet] = useState(0);
  const [open, setOpen] = useState(false);
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [monto, setMonto] = useState('$0');
  const [plazo, setPlazo] = useState(12);
  const [simEntry, setSimEntry] = useState({ monto: 0, plazo: 12, enganche: 0, interes: 0.2 });
  //errores de mensajes
  const [errorNombre, setErrorNombre] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [DNext, setDNext] = useState(false);
  //adicionales
  const [mountD, setMountD] = useState(false);
  const [SDialogOpen, setSDialogOpen] = useState(true);
  //el resto del codigo

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /*const validarDatos = () => {
    var validNombre = nombre.length > 0;
    var validEmail = email.indexOf('@') !== -1 && email.indexOf('.com') !== -1;
    var validPhone = phone.length > 0;
    if (!validNombre) {
      setErrorNombre('requerido');
    }
    if (!validEmail) {
      setErrorEmail('ingresa un correo valido');
    }
    if (!validPhone) {
      setErrorPhone('ingresa un numero de telefono valido');
    }
    return validNombre && validEmail && validPhone;
  };*/

  const validarDatosD = () => {
    var validNombre = nombre.length > 0;
    var validEmail = email.indexOf('@') !== -1 && email.indexOf('.com') !== -1;
    var validPhone = phone.length > 0;
    var validEmpresa = empresa.length > 0;
    return validNombre && validEmail && validPhone && validEmpresa;
  };
  useEffect(() => {
    if (activeStep === 1) {
      setDNext(!validarDatosD());
    }
  // eslint-disable-next-line
  }, [validarDatosD]);
  useEffect(() => {
    async function getConfig() {
      await firestore
        .collection('config')
        .doc('interes')
        .get()
        .then((doc) => {
          const dat = doc.data();
          setInteres(dat.interes);
        });
      await firestore
        .collection('config')
        .doc('enganche')
        .get()
        .then((doc) => {
          const data = doc.data();
          setEnganche(data.enganche);
        });
    }
    getConfig();
  }, []);
  const handleAlta = async () => {
    //setOpen(false);
    setSimEntry({ monto: Number(monto.replaceAll('$', '')), interes: interes, enganche: enganche, plazo: plazo });
    await firestore
      .collection('creditos')
      .add({
        Nombre: nombre,
        Telefono: phone,
        Email: email,
        Empresa: empresa,
        Monto: Number(monto.replaceAll('$', '')),
        Plazo: plazo,
        Enganche: enganche || 0,
        Tasa: interes || 0.24,
        Aprobado: false,
        Rechazado: false,
        ML: false,
        FechaAlta: toDate(Date.now()),
        numeroCred: '',
        fechaOtorgamiento: new Date()
      })
      .then(() => {
        setEmail('');
        setEmpresa('');
        setPhone('');
        setNombre('');
        setPlazo(12);
        setMonto('0');
        setOpen(false);
        setMountD(true);
        setSDialogOpen(true);
        setOpen(false);
        setActiveSpet(0);
        if (fromstart) {
          setMountD(true);
          setSDialogOpen(true);
        }
      });
  };

  const writeMonto = (value) => {
    if (monto !== '$0') {
      setMonto(String(monto + value));
    } else {
      setMonto('$' + value);
    }
  };

  const borrarMonto = () => {
    setMonto('$0');
  };

  const PLAZOS = [12, 24, 36, 48, 60];

  const pasos = (actstep) => {
    switch (actstep) {
      case 0:
        return (
          <>
            <TextField
              select
              label="plazo"
              fullWidth
              value={plazo}
              onChange={(e) => {
                setPlazo(Number(e.target.value));
              }}
            >
              {PLAZOS.map((plazoi) => {
                return <MenuItem value={plazoi}>{plazoi} meses</MenuItem>;
              })}
            </TextField>
            <Grid container sx={{ marginTop: '10px' }}>
              <Grid item xs={6}>
                <Teclado setValue={writeMonto} delValue={borrarMonto} />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color={theme.palette.secondary.main}>
                  MONTO
                </Typography>
                <TextField
                  value={monto}
                  onChange={(e) => {
                    setMonto(e.target.value);
                  }}
                  type="text"
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="h5" color="#F36D21" sx={{ fontFamily: 'HelveticaBoldP' }}>
              DATOS
            </Typography>
            <TextField
              required
              autoFocus
              fullWidth
              error={errorNombre !== '' ? true : false}
              helperText={errorNombre}
              onChange={(e) => {
                setNombre(e.target.value);
                setErrorNombre('');
                setDNext(!validarDatosD());
              }}
              value={nombre}
              name="nombre"
              type="text"
              margin="dense"
              variant="outlined"
              label="Nombre"
            />
            <TextField
              required
              autoFocus
              fullWidth
              error={errorEmail !== '' ? true : false}
              helperText={errorEmail}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorEmail('');
                setDNext(!validarDatosD());
              }}
              value={email}
              name="email"
              type="email"
              margin="dense"
              variant="outlined"
              label="Correo"
            />
            <TextField
              required
              autoFocus
              fullWidth
              error={errorPhone !== '' ? true : false}
              helperText={errorPhone}
              onChange={(e) => {
                setPhone(e.target.value);
                setErrorPhone('');
                setDNext(!validarDatosD());
              }}
              value={phone}
              name="phone"
              type="phone"
              margin="dense"
              variant="outlined"
              label="Telefono"
            />
            <TextField
              autoFocus
              fullWidth
              onChange={(e) => {
                setEmpresa(e.target.value);
                setDNext(!validarDatosD());
              }}
              value={empresa}
              name="empresa"
              type="text"
              margin="dense"
              variant="outlined"
              label="Empresa"
              required
            />
          </>
        );
      case 2:
        return (
          <>
            <>
              <Typography variant="body1">
                Haz Click en Solicitar para Finalizar y ver detalles de tu credito
              </Typography>
            </>
          </>
        );
      default:
        return <>404</>;
    }
  };

  const acciones = (actstep) => {
    switch (actstep) {
      case 0:
      case 1:
        return (
          <>
            {actstep === 0 ? null : (
              <Button
                variant="contained"
                onClick={() => {
                  if (activeStep === 1) {
                    setDNext(false);
                  }
                  setActiveSpet(activeStep - 1);
                }}
              >
                Atras
              </Button>
            )}
            <Button
              variant="contained"
              disabled={DNext}
              onClick={() => {
                if (activeStep + 1 === 1) {
                  setDNext(!validarDatosD());
                }

                setActiveSpet(activeStep + 1);
              }}
            >
              Siguiente
            </Button>
          </>
        );
      case 2:
      default:
        return (
          <>
            <Button onClick={handleClose} color="inherit">
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setActiveSpet(activeStep - 1);
              }}
            >
              atras
            </Button>
            <Button onClick={handleAlta} variant="contained">
              Solicitar
            </Button>
          </>
        );
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen} sx={sx} className={className}>
        {textbutton}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <AppBar position="relative" sx={{ borderBottom: '6px solid ' + theme.palette.secondary.main }}>
          <Toolbar>
            <Box component="p">CAPTURA TUS DATOS PARA DAR DE ALTA UN NUEVO CRÉDITO</Box>
            <IconButton
              onClick={() => {
                handleClose();
              }}
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <DialogContentText>Al capturar los datos se procesarán los datos del credito.</DialogContentText>
          <Stepper activeStep={activeStep}>
            {steps.map((item) => {
              return (
                <Step key={item}>
                  <StepLabel>{item}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box component="div" className={classes.cont}>
            {pasos(activeStep)}
          </Box>
        </DialogContent>
        <DialogActions>{acciones(activeStep)}</DialogActions>
      </Dialog>
      {mountD ? (
        <DialogAnalisis
          monto={simEntry.monto}
          plazo={simEntry.plazo}
          interes={simEntry.interes}
          enganche={simEntry.enganche}
          SDialog={SDialogOpen}
          setSDialog={setSDialogOpen}
        />
      ) : null}
    </>
  );
}

export default FormAltaCredito;
