import IconRS from 'src/components/Images/icons/IconRS';
// material
import { experimentalStyled as styled, makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Divider, Container, Typography, IconButton, Box, Link } from '@material-ui/core';
import LogoAlt from 'src/components/LogoAlt';
import { PATH_PAGE } from 'src/routes/paths';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  color: '#FFFFFF'
}));

const Styles = makeStyles((theme) => ({
  root: {},
  boxel: {
    display: 'block'
  },
  boxelred: {
    display: 'inline-block',
    textAlign: 'center',
    paddingTop: '-1%',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center'
  },
  iconosredes: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    justifyContent: 'center'
  },
  boxellinks: {
    paddingTop: '15%',
    float: 'right',
    textAlign: 'right',
    [theme.breakpoints.up('xs')]: {
      paddingTop: '1%'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '15%'
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '15%'
    }
  },
  linkB: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '22px'
    }
  },
  uppercontent: {
    marginBottom: theme.spacing(3)
  },
  belowcontent: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5)
  },
  logo: {
    display: 'inline-block',
    alignItems: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    paddingTop: '15%',
    [theme.breakpoints.up('xs')]: {
      paddingTop: '3%'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '15%'
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '15%'
    }
  }
}));

export default function MainFooter() {
  //estilos
  const classes = Styles();
  const theme = useTheme();
  return (
    <RootStyle>
      <Container>
        <Grid container spacing={1} className={classes.uppercontent}>
          <Grid item xs={12} md={4} lg={4}>
            <Box component="div" className={classes.logo}>
              <LogoAlt
                sx={{ width: 200, height: 'auto', margin: '0', padding: '0', display: 'flex', alignItems: 'left' }}
              />
              <Typography variant="h5" sx={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                <span>Real Acueducto #360 Int. 204 </span> <br />
                <span>Col. Puerta De Hierro C.P. 45116 </span>
                <br />
                <span>Zapopan, Jalisco, Mexico.</span>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            sx={{
              display: 'inline-block',
              textAlign: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              justifyContent: 'center'
            }}
          >
            <Box component="div" className={classes.boxelred}>
              <Typography variant="h5" sx={{ textAlign: 'center', marginBottom: '3%' }} className={classes.linkB}>
                SÍGUENOS
              </Typography>
              <Box component="div" className={classes.iconosredes}>
                <IconButton href="https://www.facebook.com/alirancreditos/" target="_blank">
                  <IconRS />
                </IconButton>
                <IconButton href="https://www.instagram.com/aliran_creditos/" target="_blank">
                  <IconRS variant="instagram" />
                </IconButton>
                <IconButton href="https://www.linkedin.com/company/79688846/admin/" target="_blank">
                  <IconRS variant="linkedin" />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box component="div" className={classes.boxellinks}>
              <Typography variant="h5" sx={{ marginBottom: '10%' }}>
                <Link href={PATH_PAGE.contact} color="#FFF" className={classes.linkB}>
                  CONTACTO
                </Link>
              </Typography>
              <Typography variant="h5">
                <Link href={PATH_PAGE.faqs} color="#FFF" className={classes.linkB}>
                  Preguntas Frecuentes
                </Link>
              </Typography>
              <Typography variant="h5">
                <Link href="/" color="#FFF" className={classes.linkB}>
                  Términos y Condiciones
                </Link>
              </Typography>
              <Typography variant="h5">
                <Link href={PATH_PAGE.avisoPrivacidad} color={theme.palette.secondary.main} className={classes.linkB}>
                  Aviso de Privacidad
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ border: 'solid #FFF 1px', backgroundColor: '#FFF' }} />
        <Box component="div" className={classes.belowcontent}>
          <Typography variant="body1" sx={{ fontSize: '11px', textAlign: 'justify', textJustify: 'inter-word' }}>
            ALIRAN CORPORATION, en su constitucion y operación no se reuquiere de autorización de secretaria de hacienda
            y credito publico, no obstante, se encuentra sujeta a la supervicion de la comisión Nacional Bancaria y de
            valores, únicamente para efectos de lo dispuesto por el articulo 56 de la Ley General de Organizaciones y
            Actividades Auxiliares de Crédito. Aliran © 2021. TODOS LOS DERECHOS RESERVADOS, PROHIBIDA LA REPRODUCCION
            TOTAL O PARCIAL DEL CONTENIDO DE ESTE SITIO.
          </Typography>
        </Box>
      </Container>
    </RootStyle>
  );
}
