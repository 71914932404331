import React, { Component } from 'react';
import { Dialog, IconButton, Grid, AppBar, Toolbar, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import TablaAmortizacion from './TablaAmortizacion';
//import GraficaAmort from './GraficaAmort';
//import GraficaSaldo from './GraficaSaldo';
//import GraficaPagoTotal from './GraficaPagoTotal';
import PropTypes from 'prop-types';
const axios = require('axios').default;

class DialogAnalisis extends Component {
  state = {
    Dopen: true,
    creditS: [],
    show: false,
    loading: false,
    error: false,
    errorText: ''
  };

  closeDialog() {
    this.props.setSDialog(false);
  }

  async componentDidMount() {
    this.setState({ creditsS: [] });
    this.setState({
      show: false,
      loading: true,
      error: false
    });
    await axios
      .get('https://us-central1-botsi-oxrr.cloudfunctions.net/aliransimulador', {
        params: {
          monto: this.props.monto,
          interesanual: this.props.interes * 100,
          plazo: this.props.plazo,
          enganche: this.props.enganche * 100
        }
      })
      .then((resp) => {
        try {
          this.setState({
            creditS: resp.data.periodos,
            loading: false,
            show: true
          });
          console.log(this.state);
        } catch (err) {
          console.log('error intentando obtener la informacion' + err);
          this.setState({
            errorText: err,
            error: true,
            loading: false
          });
        }
      });
  }

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.SDialog}
        onClose={() => {
          this.closeDialog();
        }}
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              onClick={() => {
                this.closeDialog();
              }}
            >
              <Close />
            </IconButton>
            <Typography variant="h5">Analisis del Crédito</Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {this.state.loading ? <h1>Cargando...</h1> : null}
            {this.state.error ? <h1>La solicitud a fallado {this.state.errorText}</h1> : null}
            {/*this.state.creditS.length !== 0 && this.state.show ? <GraficaAmort Cred={this.state.creditS} /> : null*/}
          </Grid>
          {/*<Grid item xs={12} md={6}>
            {this.state.creditS.length !== 0 && this.state.show ? <GraficaSaldo Cred={this.state.creditS} /> : null}
          </Grid>
          <Grid item xs={12} md={6}>
            {this.state.creditS.length !== 0 && this.state.show ? <GraficaPagoTotal Cred={this.state.creditS} /> : null}
          </Grid>*/}
          <Grid item xs={12} md={12} lg={12}>
            {this.state.creditS.length !== 0 && this.state.show ? (
              <TablaAmortizacion Cred={this.state.creditS} />
            ) : null}
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

DialogAnalisis.propTypes = {
  monto: PropTypes.number.isRequired,
  plazo: PropTypes.number.isRequired,
  interes: PropTypes.number.isRequired,
  enganche: PropTypes.number.isRequired,
  SDialog: PropTypes.bool.isRequired,
  setSDialog: PropTypes.func.isRequired
};

export default DialogAnalisis;
