// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import ComingSoon from './ComingSoon';
// components
import Page from '../components/Page';
//import HeroAliran from 'src/components/atomicDesign/HeroAliran';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11)
  }
}));

// ----------------------------------------------------------------------

export default function WinWithUs() {
  return (
    <RootStyle title="Acerca de  | Aliran">
      {/*<HeroAliran
        Titulo="Quienes somos"
        Descripcion="Otorgamos créditos de forma simple"
        BotonTexto='Contactar'
        Imagen={'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80'}
      />*/}
      <ComingSoon />
    </RootStyle>
  );
}
