import React, { createContext } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

export const videoContext = createContext();

function VideoContext({ children }) {
  const [vid, setVid] = useLocalStorage('video', false);
  return <videoContext.Provider value={{ vid: vid, setVid: setVid }}>{children}</videoContext.Provider>;
}

export default VideoContext;
