import React from 'react';
// material
import { Box } from '@material-ui/core';
import { experimentalStyled as styled, makeStyles } from '@material-ui/core/styles';
import { ProductPresent } from 'src/components/_external-pages/products';
// components
import Page from '../components/Page';
// ----------------------------------------------------------------------
//imgs
import prod1 from 'src/components/Images/producto1.jpg';

const Styles = makeStyles((theme) => ({
  parraf: {
    marginTop: '1%',
    marginBottom: '2%'
  }
}));

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(11),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11)
  }
}));

// ----------------------------------------------------------------------

export default function CreditoSimple() {
  const classes = Styles();
  const CS = {
    title: 'Crédito Simple y Refaccionario',
    subtitle: 'A la medida de tus necesidades',
    description: (
      <>
        <Box component="p" className={classes.parraf}>
          Éste producto esta diseñado para la inyección de capital a tu negocio, ajustandose a los plazos de tu
          actividad. Crédito con características acorde al plazo y producto, dirigido para personas morales y físicas
          con actividad empresarial.
        </Box>
        <Box component="p" className={classes.parraf}>
          El plazo de este producto puede ir desde 6 hasta 60 meses, dependiendo su destino final. Deberá contar con una
          garantía de respaldo. La tasa de interés dependerá de las características de cada producto.
        </Box>
      </>
    ),
    img: prod1
  };
  return (
    <>
      <RootStyle title="Aliran | Credito Simple">
        <ProductPresent title={CS.title} subtitle={CS.subtitle} description={CS.description} img={CS.img} index={0} />
      </RootStyle>
    </>
  );
}
