import React from 'react';
import { Container, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TitlePage, ProductPresent } from 'src/components/_external-pages/products';
import { PATH_PAGE } from 'src/routes/paths';
import Page from 'src/components/Page';
//imagenes
import prod1 from 'src/components/Images/producto1.jpg';
import prod2 from 'src/components/Images/producto2.jpg';
import prod3 from 'src/components/Images/producto3.jpg';

const Styles = makeStyles((theme) => ({
  root: {
    marginTop: '7.2em'
  }
}));

function Products() {
  //estilos
  const classes = Styles();
  //info duh
  const INFO = [
    {
      title: 'Crédito Simple',
      subtitle: 'A la medida de tus necesidades',
      description:
        'Éste producto esta diseñado para la inyección de capital a tu negocio, ajustandose a los plazos de tu actividad.',
      img: prod1,
      link: PATH_PAGE.productcs
    },
    {
      title: 'Arrendamiento Financiero',
      subtitle: 'Tus Finanzas optimizadas',
      description:
        'Al tener un esquema de arrendamineto, Éste esquema está diseñado para perosnas físicas con actividad empresarial o personas morales, con la finalidad de adquirir activos productivos con la apertura de la opción de compra.',
      img: prod2,
      link: PATH_PAGE.productaf
    },
    {
      title: 'Crédito Prendario',
      subtitle: 'Tu garantía rinde',
      description:
        'Con éste producto, proporcionamos flujo de efectivo sin la necesidad de un fideicomiso, con un contrato simple y tomando en cuenta como prenda, vehículos o relojes',
      img: prod3,
      link: PATH_PAGE.productcp
    }
  ];
  return (
    <>
      <Page title="Aliran | Productos">
        <Container className={classes.root}>
          <TitlePage title="PRODUCTOS FINANCIEROS" />
          <Box variant="div">
            {INFO.map((item, index) => {
              return (
                <>
                  <ProductPresent
                    title={item.title}
                    subtitle={item.subtitle}
                    description={item.description}
                    img={item.img}
                    index={index}
                    link={item.link}
                  />
                  <Divider color="#000" />
                </>
              );
            })}
          </Box>
        </Container>
      </Page>
    </>
  );
}

export default Products;
