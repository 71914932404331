import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// material
import { Box, Grid } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { CallToAction } from 'src/components/_external-pages/calltoaction';
// components
import { firestore } from 'src/contexts/FirebaseContext';
import CardCTA from './CardCTA';
import Page from '../Page';
// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
  padding: theme.spacing(0, 0, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 0, 0)
  }
}));

// ----------------------------------------------------------------------

export default function LPLayout() {
  //estados
  const params = useLocation().search;
  const CTA = new URLSearchParams(params).get('cta');
  const [data, setData] = useState([]);
  useEffect(() => {
    async function getData() {
      await firestore.collection('ctas').onSnapshot((queryS) => {
        let dat = [];
        queryS.forEach((doc) => {
          dat.push({ ...doc.data() });
        });
        if (CTA) {
          setData(dat.filter((cta) => cta.titulo === CTA.replaceAll('_', ' ')));
        } else {
          setData(dat);
        }
      });
    }
    getData();
  }, [CTA]);
  return (
    <>
      <Page title={CTA && data[0] ? data[0].titulo : 'Call to Actions'}>
        <HeaderStyle>
          {CTA ? (
            <Box component="div" sx={{ width: '100%', height: '100%' }}>
              {data.map((cta) => {
                return (
                  <CallToAction
                    title={cta.titulo}
                    mediaType={cta.mediaType}
                    mediaURL={cta.file}
                    description={cta.description}
                    BLink={cta.link}
                    textButton={cta.textButton}
                    formulario={cta.formulario}
                    redirectURL={cta.redirectURL}
                    formURL={cta.formURL}
                    typeF={cta.typeF}
                    FOpc={cta.FOpc}
                  />
                );
              })}
            </Box>
          ) : (
            <Grid container>
              {data.map((cta) => {
                return (
                  <Grid item xs={12} md={4} lg={3} xl={2}>
                    <CardCTA title={cta.titulo} mediaType={cta.mediaType} mediaURL={cta.file} />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </HeaderStyle>
      </Page>
    </>
  );
}
