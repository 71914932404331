import { useRoutes } from 'react-router-dom';
import { LandingPage, About, Products, AvisoPrivacidad, Contact, Productcs, Productaf, Productcp } from './seo';
import { PATH_PAGE } from './paths';

function SEO() {
  return useRoutes([
    {
      path: '/',
      element: <LandingPage />
    },
    {
      path: PATH_PAGE.products,
      element: <Products />
    },
    {
      path: PATH_PAGE.productcs,
      element: <Productcs />
    },
    {
      path: PATH_PAGE.productaf,
      element: <Productaf />
    },
    {
      path: PATH_PAGE.productcp,
      element: <Productcp />
    },
    {
      path: PATH_PAGE.about,
      element: <About />
    },
    {
      path: PATH_PAGE.avisoPrivacidad,
      element: <AvisoPrivacidad />
    },
    {
      path: PATH_PAGE.contact,
      element: <Contact />
    }
  ]);
}

export default SEO;
