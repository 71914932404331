import React from 'react';
import { Helmet } from 'react-helmet-async';

function AboutSEO() {
  return (
    <Helmet>
      <title>Aliran | Quienes Somos?</title>
      <meta name="keywords" content="creditos, aliran, quienes somos" />
      <meta name="description" content="Aliran quienes Somos" />

      <meta itemprop="name" content="Aliran - Quienes Somos?" />
      <meta itemprop="description" content="Aliran quienes Somos" />
      <meta itemprop="image" content="" />

      <meta property="og:url" content="https://aliran.com.mx/about-us" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Aliran - Quienes Somos?" />
      <meta property="og:description" content="Aliran quienes Somos" />
      <meta property="og:image" content="" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Aliran - Quienes Somos?" />
      <meta name="twitter:description" content="Aliran quienes Somos" />
      <meta name="twitter:image" content="" />
    </Helmet>
  );
}

export default AboutSEO;
