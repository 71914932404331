import React from 'react';
import { Box, Typography, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

const Styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    textAlign: 'center'
  },
  iconcontainer: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-around'
  },
  textcontainer: {
    textAlign: 'center',
    lineHeight: 'normal'
  }
}));

Icon.propTypes = {
  info: PropTypes.shape({
    tittle: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    des: PropTypes.string.isRequired
  }).isRequired,
  iconSize: PropTypes.object,
  containerStyles: PropTypes.object,
  titleStyles: PropTypes.object,
  desStyles: PropTypes.object,
  textContainerStyles: PropTypes.object,
  iconContainerStyles: PropTypes.object
};

function IconP({ info, iconSize, iconContainerStyles, containerStyles, textContainerStyles, titleStyles, desStyles }) {
  //estilos
  const classes = Styles();
  //metodos
  return (
    <>
      <Box component="div" className={classes.root} sx={containerStyles}>
        <Box component="div" className={classes.iconcontainer} sx={iconContainerStyles}>
          <Box component="img" src={info.icon} sx={{ width: 110, height: 110, ...iconSize }} />
        </Box>
        <Box component="div" calsssName={classes.textcontainer} sx={textContainerStyles}>
          <Typography
            variant="h3"
            style={{ lineHeight: 'normal', textAlign: 'center', margin: 0, padding: 0, ...titleStyles }}
          >
            {info.tittle}
          </Typography>
          <Typography
            variant="h5"
            style={{ lineHeight: 'normal', textAlign: 'center', margin: 0, padding: 0, ...desStyles }}
          >
            {parse(info.des)}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default IconP;
