import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Icon from './Icon';
import aprobacion from 'src/components/Images/iconosv/aprobacion.png';
import atencion from 'src/components/Images/iconosv/atencion.png';
import burocredito from 'src/components/Images/iconosv/burocredito.png';
import calificacion from 'src/components/Images/iconosv/calificacion.png';
import disponibilidad from 'src/components/Images/iconosv/disponibilidad.png';
import financiamineto from 'src/components/Images/iconosv/financiamineto.png';

const Styles = makeStyles((theme) => ({
  root: {
    marginTop: '7%',
    marginBottom: '50px',
    paddingLeft: '10%',
    paddingRight: '10%'
  },
  titulo: {
    textAlign: 'center'
  }
}));

function Iconos() {
  //estilos
  const classes = Styles();
  //estados
  //const [INFO, setInfo] = useState([]);
  const INFO = [
    { tittle: 'Financiamiento', des: 'Desde $500,000MXN', icon: financiamineto },
    { tittle: 'Disponibilidad de fondeo', des: 'desde 48 horas después de la autorización', icon: calificacion },
    { tittle: 'Disponibilidad', des: 'de fondeo en 15 días', icon: disponibilidad },
    { tittle: 'Buro de Crédito', des: 'no es deternimante', icon: burocredito }
  ];
  const INFO1 = [
    { tittle: 'Atención', des: 'Directa y personalizada', icon: atencion },
    { tittle: 'Aprobación', des: 'alto nivel de gestiones', icon: aprobacion }
  ];
  //metodos
  /*useEffect(() => {
    async function getData() {
      await firestore
        .collection('config')
        .doc('iconos')
        .get()
        .then((doc) => {
          if (doc.exists) {
            setInfo(doc.data().value || []);
            //setTitulo(doc.data().titulo || '');
            //setSubtitulo(doc.data().subtitulo || '');
            //setBcolor(doc.data().Bcolor || 'transparent');
            //setTcolor(doc.data().Tcolor || '#000000');
          }
        });
    }
    getData();
  }, []);*/

  return (
    <>
      <Box component="div" className={classes.root} style={{ color: '#6D6F71' }}>
        <Box className={classes.titulo}>
          <Typography
            variant="h3"
            style={{ textAlign: 'center', fontFamily: 'Helvetica', fontWeight: '300', marginBottom: '2.4rem' }}
          >
            NUESTRAS VENTAJAS COMPETITIVAS
          </Typography>
          <Typography variant="h4" style={{ textAlign: 'center' }}></Typography>
        </Box>
        <Box sx={{ justifyContent: 'center', display: 'inline-flex', flexWrap: 'wrap' }}>
          <Grid container spacing={2} sx={{ flex: '0 0 100%' }}>
            {INFO.map((item) => {
              return (
                <Grid item xs={12} md={3} lg={3}>
                  <Icon
                    info={item}
                    iconSize={{ width: 85, height: 85 }}
                    titleStyles={{ fontSize: '27px' }}
                    desStyles={{ fontSize: '22px' }}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={2} sx={{ maxWidth: '545px', flex: ' 0 0 100%' }}>
            {INFO1.map((item) => {
              return (
                <Grid item xs={12} md={6} lg={6}>
                  <Icon
                    info={item}
                    iconSize={{ width: 85, height: 85 }}
                    titleStyles={{ fontSize: '27px' }}
                    desStyles={{ fontSize: '22px' }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Iconos;
