import React from 'react';
import { Grid, Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

Teclado.propTypes = {
  setValue: PropTypes.func.isRequired,
  delValue: PropTypes.func.isRequired
};

const NUMBERS = [1, 2, 3, 4, 5, 6, 7, 8, 9];

function Teclado({ setValue, delValue }) {
  //estilos
  return (
    <>
      <Box component="div">
        <Grid container spacing={1}>
          {NUMBERS.map((num) => {
            return (
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setValue(num);
                  }}
                >
                  {num}
                </Button>
              </Grid>
            );
          })}
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setValue(0);
              }}
            >
              0
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                delValue();
              }}
            >
              Borrar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Teclado;
