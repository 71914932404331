import PropTypes from 'prop-types';
// material
import { useTheme, experimentalStyled as styled, makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography, LinearProgress } from '@material-ui/core';
// utils
import { fPercent } from '../../../utils/formatNumber';
//

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'justify',
  textJustify: 'inter-word',
  marginTop: -5,
  marginBottom: -60
}));

// ----------------------------------------------------------------------

ProgressItem.propTypes = {
  progress: PropTypes.object
};

function ProgressItem({ progress }) {
  const { label, value } = progress;
  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mb: 1.5, display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle2">{label}&nbsp;-&nbsp;</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {fPercent(value)}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          '& .MuiLinearProgress-bar': { bgcolor: 'grey.700' },
          '&.MuiLinearProgress-determinate': { bgcolor: 'divider' }
        }}
      />
    </Box>
  );
}

const Styles = makeStyles((theme) => ({
  root: {
    fontSize: '25px',
    color: '#6D6F71'
  }
}));

export default function AboutWhat() {
  const classes = Styles();
  const theme = useTheme();
  //const isLight = theme.palette.mode === 'light';
  //const shadow = `-40px 40px 80px ${alpha(isLight ? theme.palette.grey[500] : theme.palette.common.black, 0.48)}`;
  const textBold = {
    color: theme.palette.secondary.main,
    fontFamily: 'HelveticaBoldP'
  };

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box component="p" className={classes.root}>
          <span style={textBold}>Aliran, </span>
          es la empresa líder en el mercado de <span style={textBold}>Créditos a la medida. </span>
          Nuestro principal compromiso es hacer la diferencia todos los días brindando{' '}
          <span style={textBold}>préstamos ágiles y efectivos</span> de una manera transparente y responsable.
        </Box>
      </Container>
    </RootStyle>
  );
}
