import React from 'react';
import { Box } from '@material-ui/core';
import facebookicon from './facebookicon.png';
import instagram from './instagramicon.png';
import linkedinicon from './linkedinicon.png';

const getIcon = (icon) => {
  switch (icon) {
    case 'facebook':
      return facebookicon;
    case 'instagram':
      return instagram;
    case 'linkedin':
      return linkedinicon;
    default:
      return '';
  }
};

function IconRS({ sx, variant = 'facebook' }) {
  return <Box component="img" src={getIcon(variant)} sx={{ width: 50, height: 50, ...sx }} />;
}

export default IconRS;
