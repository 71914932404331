import { alpha } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const PRIMARY = {
  lighter: '#FEEDD2',
  light: '#FBB879',
  main: '#F36D21',
  dark: '#AE3610',
  darker: '',
  contrastText: '#fff'
};
const SECONDARY = {
  lighter: '#D0F6F2',
  light: '#6DC9CD',
  main: '#144D5B',
  dark: '#0A2E41',
  darker: '#03172B',
  contrastText: '#fff'
};
const INFO = {
  lighter: '#CDFDF8',
  light: '#69F0F5',
  main: '#0BB8E0',
  dark: '#056CA1',
  darker: '#02386B',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#CBFDF9',
  light: '#63EFF7',
  main: '#01B7E6',
  dark: '#006BA5',
  darker: '#00376E',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#FDFBCD',
  light: '#F8EF6A',
  main: '#EAD80B',
  dark: '#A89805',
  darker: '#706302',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#FEECD3',
  light: '#FDB47D',
  main: '#F96627',
  dark: '#B32E13',
  darker: '#770B07',
  contrastText: '#fff'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

const palette = {
  light: {
    ...COMMON,
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action }
  },
  dark: {
    ...COMMON,
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action }
  }
};

export default palette;
