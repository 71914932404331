import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Icon from './Icon';
import Paso1 from 'src/components/Images/paso1.png';
import Paso2 from 'src/components/Images/paso2.png';
import Paso3 from 'src/components/Images/paso3.png';

const Styles = makeStyles((theme) => ({
  root: {
    marginTop: '8%',
    marginBottom: '7%',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '8%',
      paddingRight: '8%'
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '6%',
      paddingRight: '6%'
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '4%',
      paddingRight: '4%'
    }
  },
  titulo: {
    textAlign: 'center',
    width: '100%'
  }
}));

function Iconos() {
  //estilos
  const classes = Styles();
  //estados
  const INFO = [
    {
      tittle: 'Rellena un formulario',
      des: '',
      icon: Paso1
    },
    {
      tittle: 'Elíge entre múltiples ofertas',
      des: '',
      icon: Paso2
    },
    {
      tittle: 'Envía tu selección y obtén tu dinero',
      des: '',
      icon: Paso3
    }
  ];

  return (
    <>
      <Box component="div" className={classes.root} style={{ color: '#6D6F71' }}>
        <Box className={classes.titulo}>
          <Typography
            variant="h3"
            style={{ textAlign: 'center', fontFamily: 'Helvetica', fontWeight: '300', marginBottom: '2.8rem' }}
          >
            ¿CÓMO FUNCIONA?
          </Typography>
        </Box>
        <Box component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container spacing={2}>
            {INFO.map((item) => {
              return (
                <Grid item xs={12} md={12} lg={4} display="flex" justifyContent="center">
                  <Icon
                    info={item}
                    titleStyles={{ lineHeight: 'normal', fontSize: '1.8rem' }}
                    containerStyles={{ display: 'inline-flex' }}
                    textContainerStyles={{
                      marginLeft: '3%',
                      marginTop: '5% !important',
                      maxWidth: '250px',
                      flex: '0 250px',
                      '& h3': { textAlign: 'left !important' }
                    }}
                    iconContainerStyles={{ width: 110, height: 110, flex: '0 110px' }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Iconos;
