import PropTypes from 'prop-types';
// material
//import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import svgAliran from 'src/assets/Logoaliranblanco.svg';

// ----------------------------------------------------------------------

LogoAlt.propTypes = {
  sx: PropTypes.object
};

export default function LogoAlt({ sx, className }) {
  //const theme = useTheme();
  //const PRIMARY_LIGHT = theme.palette.primary.light;
  //const PRIMARY_MAIN = theme.palette.primary.main;
  //const PRIMARY_DARK = theme.palette.primary.dark;

  return <Box sx={{ width: 130, height: 130, ...sx }} className={className} component="img" src={svgAliran} />;
}
