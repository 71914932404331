// material
import { Box, Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//imagenes
import img1 from 'src/components/Images/whoweare1.jpg';
import img2 from 'src/components/Images/whoweare2.jpg';

// ----------------------------------------------------------------------

const Styles = makeStyles((theme) => ({
  spacing: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3)
  },
  title: {
    fontFamily: 'HelveticaBoldP',
    color: theme.palette.secondary.main,
    textAlign: 'center'
  },
  parraf: {
    textAlign: 'justify',
    textJustify: 'inter-word'
  },
  container: {
    padding: theme.spacing(1)
  }
}));

export default function AboutVision() {
  //estilos
  const classes = Styles();
  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <Grid container className={classes.spacing}>
        <Grid item xs={12} md={6} lg={6}>
          <Box
            component="p"
            color="#6D6F71"
            style={{ fontSize: '24px', textAlign: 'justify', textJustify: 'inter-word' }}
          >
            Iniciamos operaciones en el año 2018, en el municipio de Zapopan, Jalisco, con el sueño de innovar y
            transformar los servicios fnancieros en México. Hoy somos una plataforma multiproducto, que ofrece acceso a
            inversión, ingresos y a fnanciamientos que fortalecen el impulso, en la mejora de la calidad de vida
            personal y fnanciera de la sociedad mexicana.
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className={classes.container}>
          <Box component="img" src={img1} />
        </Grid>
      </Grid>
      <Grid container className={classes.spacing}>
        <Grid item xs={12} md={6} lg={6} className={classes.container}>
          <Box component="img" src={img2} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Typography variant="h3" className={classes.title}>
            Misión
          </Typography>
          <Box
            component="p"
            color="#6D6F71"
            className={classes.parraf}
            style={{ fontSize: '20px', textAlign: 'justify', textJustify: 'inter-word' }}
          >
            Ofrecer soluciones fnancieras sencillas que promuevan el crecimiento de nuestros clientes con productos
            específcos a la medida de sus necesidades.
          </Box>
          <Typography variant="h3" className={classes.title}>
            Visión
          </Typography>
          <Box component="p" color="#6D6F71" className={classes.parraf} style={{ fontSize: '20px' }}>
            Lograr un crecimiento exponencial de la mano de nuestros clientes y colaboradores
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
