// import MapGL from 'react-map-gl';
import React from 'react';
// import { Icon } from '@iconify/react';
// import phoneFill from '@iconify/icons-eva/phone-fill';
// material
import { experimentalStyled as styled, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
//
// import { MapControlPopup, MapControlMarker, MapControlScale, MapControlNavigation } from '../../map';
// import { mapConfig } from '../../../config';
import { varFadeIn, MotionInView } from '../../animate';

// ----------------------------------------------------------------------

const Styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  title: {
    fontFamily: 'HelveticaBoldP',
    color: theme.palette.secondary.main
  },
  subtitle: {
    color: '#6D6F71',
    marginBottom: '5%'
  },
  parraf: {
    color: '#6D6F71',
    textAlign: 'justify',
    textJustify: 'inter-word'
  }
}));

export const ADDRESS = [
  {
    latlng: [20.7119524, -103.4096474],
    address: 'Real de Acueducto No. 360',
    phoneNumber: '(33) 14250331'
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: 0,
  height: 560,
  overflow: 'hidden',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  '& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
    display: 'none'
  }
}));

// ----------------------------------------------------------------------

export default function ContactMap() {
  //const theme = useTheme();
  const classes = Styles();
  /*const isLight = theme.palette.mode === 'light';
  const [tooltip, setTooltip] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: 20.7119524,
    longitude: -103.4096474,
    zoom: 10
  });*/

  return (
    <MotionInView variants={varFadeIn}>
      <RootStyle>
        <Typography variant="h5" className={classes.subtitle}>
          Real Acueducto #360 Int. 204 Col. Puerta De Hierro C.P. 45116 Zapopan, Jalisco, Mexico.
          <a href="tel:3314250331">Tel. (33) 14250331</a>
        </Typography>
        <iframe
          title="mapa ubicacion"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3731.9525017929323!2d-103.411510585627!3d20.71215330388823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428af0140c0b67d%3A0x2bafc981d8f78f92!2sReal%20de%20Acueducto%20360-Int.%20204%2C%20Puerta%20de%20Hierro%2C%2045116%20Zapopan%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1630005597543!5m2!1ses-419!2smx"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
        />
        {/*<MapGL
          {...viewport}
          onViewportChange={setViewport}
          mapStyle={`mapbox://styles/mapbox/${isLight ? 'light' : 'dark'}-v10`}
          mapboxApiAccessToken={mapConfig}
          width="100%"
          height="100%"
        >
          <MapControlScale />
          <MapControlNavigation />

          {ADDRESS.map((country) => (
            <MapControlMarker
              key={country.latlng}
              latitude={country.latlng[0]}
              longitude={country.latlng[1]}
              onClick={() => setTooltip(country)}
            />
          ))}

          {tooltip && (
            <MapControlPopup
              longitude={tooltip.latlng[1]}
              latitude={tooltip.latlng[0]}
              onClose={() => setTooltip(null)}
              sx={{
                '& .mapboxgl-popup-content': { bgcolor: 'common.white' },
                '&.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip': { borderTopColor: '#FFF' },
                '&.mapboxgl-popup-anchor-top .mapboxgl-popup-tip': { borderBottomColor: '#FFF' }
              }}
            >
              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                Address
              </Typography>
              <Typography component="p" variant="caption">
                {tooltip.address}
              </Typography>

              <Typography component="p" variant="caption" sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                <Box component={Icon} icon={phoneFill} sx={{ mr: 0.5, width: 14, height: 14 }} />
                {tooltip.phoneNumber}
              </Typography>
            </MapControlPopup>
          )}
        </MapGL>*/}
      </RootStyle>
    </MotionInView>
  );
}
