// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Grid, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// components
import Page from '../components/Page';
import { ContactForm, ContactMap } from '../components/_external-pages/contact';
import HeroAliran from 'src/components/atomicDesign/HeroAliran';
import imgcontacto from 'src/components/Images/contactoaliran.jpg';

// ----------------------------------------------------------------------

const Styles = makeStyles((theme) => ({
  desh: {
    fontSize: '100px',
    fontFamily: 'HelveticaBoldP',
    paddingLeft: '18px',
    [theme.breakpoints.up('xs')]: {
      fontSize: '60px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '100px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '100px'
    }
  }
}));

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11)
  }
}));

// ----------------------------------------------------------------------

export default function Contact() {
  const classes = Styles();
  return (
    <RootStyle title="Contacto | Aliran">
      <HeroAliran
        Titulo="¿Donde"
        Descripcion={
          <Typography variant="h2" className={classes.desh}>
            encontrarnos?
          </Typography>
        }
        BotonTexto="Contactar"
        Imagen={imgcontacto}
      />

      <Container sx={{ my: 10 }}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <ContactForm />
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactMap />
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
