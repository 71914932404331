import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Page from '../components/Page';

const Styles = makeStyles((theme) => ({
  root: {},
  title: {
    textAlign: 'center',
    fontFamily: 'HelveticaBoldP',
    color: '#6D6F71'
  },
  parraf: {
    paddingLeft: '5%',
    paddingRight: '5%',
    color: '#6D6F71',
    fontSize: '23px',
    textAlign: 'justify',
    textJustify: 'inter-word'
  }
}));

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(14),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(14)
  }
}));

function AvisoPrivacidad() {
  //estilos
  const classes = Styles();
  return (
    <>
      <RootStyle title="Aliran | Aviso de Privacidad">
        <Typography variant="h2" className={classes.title}>
          AVISO DE PRIVACIDAD
        </Typography>
        <Box className={classes.parraf}>
          ALIRAN SA DE CV, con domicilio.'en Av. Real Acueducto No. 360 Int. 204, Torre Afil, Col. Puerta de Hierro
          Zapopan, Jalisco C.P. 45116, de acuerdo con las disposiciones establecidas en la Ley Federal de Protección de
          Datos Personales en Posesión de los Particulares; es responsable de recabar sus datos personales, del uso que
          se le dé alos mismos, así como de su protección.
        </Box>
        <Box className={classes.parraf}>
          La Información personal proporcionada a esta institución, subsidiarias y/o afiliadas, será utilizada para
          entre otros fines, de manera enunciativa mas no limitativa para identificar a sus solicitantes y/o clientes
          referentes a celebrar un contrato de crédito, informarle sobre el estatus del mismo, realizar requerimientos
          de pago, ofrecerle diferentes productos y/o servicios además de informarle sobre cambios en los mismos y
          evaluar la calidad del servicio que le brindamos. En caso de formalizar con Usted la aceptación de algún
          producto o servicio ofrecido por ALIRAN CORPORATION SA DE CV., sus Datos serán utilizados para el cumplimiento
          de las obligaciones derivadas de esa relación jurídica.
        </Box>
        <Box className={classes.parraf}>
          Para las finalidades antes mencionadas, requerimos obtener entre otros, los siguientes datos personales:
          Nombre, Dirección, Teléfono (s), correo electrónico, edad, sexo, lugar y fecha de nacimiento, R.F.C.,
          C.U.R.P., etc., manifestando que no se pedirán datos personales catalogados como sensibles, según la Ley
          Federal de Protección de Datos Personales en Posesión de los Particulares.
        </Box>
        <Box className={classes.parraf}>
          Asimismo, le informamos que sus datos personales serán protegidos, pero pueden ser transferidos a las empresas
          controladoras, subsidiarias o afiliadas a ALIRAN CORPORATION SA DE CY. y/o con quien tenga una relación
          jurídica o de negocio, a efecto de proveerle adecuadamente los servicios y productos que ha solicitado o bien
          para entablar cualquier tipo de relación jurídica o de negocios, salvo que el titular respectivo de los datos
          manifieste expresamente su oposición, en términos de lo dispuesto por la Ley Federal de Protección de Datos
          Personales en Posesión de los Particulares.
        </Box>
        <Box className={classes.parraf}>
          Los Datos Personales se recaban y tratan de manera lícita conforme a las disposiciones establecidas por la
          citada Ley. Usted tiene derecho de acceder, rectificar y cancelar sus datos personales, así como de oponerse
          al tratamiento de los mismos o revocar el consentimiento que para tal fin nos haya otorgado, a través de los
          procedimientos que hemos implementado y que pondremos a su disposición si usted lo solicita de acuerdo a su
          interés.
        </Box>
        <Box className={classes.parraf}>
          Para conocer dichos procedimientos, los requisitos y plazos, se puede poner en contacto con nuestro
          departamento de datos personales ubicado en: Av. Real acueducto No. 360 int 204, Torre Afil, Col. Puerta de
          Hierro Zapopan, Jalisco C.P. 45116, o al número telefónico (33)3110-1192. y o através del correo electrónico:
          promocionOaliran.com.mx
        </Box>
        <Box className={classes.parraf}>
          ALIRAN CORPORATION SA DE CV, se reserva el derecho de cambiar, modificar, complementar y/o alterar el presente
          aviso, en cualquier momento, en cuyo caso se hará de su conocimiento a través de cualquiera de los medios que
          establece la legislación en la materia.
        </Box>
      </RootStyle>
    </>
  );
}

export default AvisoPrivacidad;
