import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Solucion from './Solucion';
import { firestore } from 'src/contexts/FirebaseContext';

const Styles = makeStyles((theme) => ({
  root: {
    paddingLeft: '15%',
    paddingRight: '15%'
  },
  titulo: {
    paddingRight: '40%',
    paddingLeft: '40%'
  }
}));

function Soluciones() {
  //estilos
  const classes = Styles();
  //estados
  const [titulo, setTitulo] = useState('');
  const [subtitulo, setSubtitulo] = useState('');
  const [INFO, setInfo] = useState([]);
  //metodos
  useEffect(() => {
    async function getData() {
      await firestore
        .collection('config')
        .doc('soluciones')
        .get()
        .then((doc) => {
          if (doc.exists) {
            setInfo(doc.data().value || []);
            setTitulo(doc.data().titulo || '');
            setSubtitulo(doc.data().subtitulo || '');
          }
        });
    }
    getData();
  }, []);

  return (
    <>
      <Box component="div" className={classes.root}>
        <Box className={classes.titulo}>
          <Typography variant="h3">{titulo}</Typography>
          <Typography variant="h4">{subtitulo}</Typography>
        </Box>
        <Grid container spacing={2}>
          {INFO.map((item, index) => {
            return (
              <Grid item xs={12}>
                <Solucion info={item} number={index} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}

export default Soluciones;
