import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, Typography, Icon, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

const Styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center'
  },
  iconcontainer: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    justifyContent: 'center'
  },
  textcontainer: {
    marginTop: '0px',
    padding: 0,
    textAlign: 'center'
  }
}));

Icon.propTypes = {
  info: PropTypes.shape({
    tittle: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    des: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  }).isRequired,
  iconSize: PropTypes.object.isRequired,
  titleStyles: PropTypes.object.isRequired,
  desStyles: PropTypes.object.isRequired
};

function IconHover({ info, iconSize, titleStyles, desStyles }) {
  //estilos
  const classes = Styles();
  //metodos
  return (
    <>
      <Box component="div" className={classes.root}>
        <Link to={info.link} component={RouterLink}>
          <Box component="div" className={classes.iconcontainer}>
            <Box
              component="img"
              src={info.icon}
              sx={{ width: 110, height: 110, alignContent: 'center', ...iconSize }}
            />
          </Box>
          <Box component="div" calsssName={classes.textcontainer}>
            <Typography variant="h3" style={{ textAlign: 'center', margin: 0, padding: 0, ...titleStyles }}>
              {info.tittle}
            </Typography>
            <Typography variant="h5" style={{ textAlign: 'center', margin: -8, padding: 0, ...desStyles }}>
              {parse(info.des)}
            </Typography>
          </Box>
        </Link>
      </Box>
    </>
  );
}

export default IconHover;
