import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import FormAltaCredito from 'src/pages/dashboard/Creditos/altas/FormAltaCredito';
import VideoHero from './VideoHero';
import useVid from 'src/hooks/useVid';

// import FMBoton from './FMBoton';
// import Button from './Button';
// import Lista from './lista';
// import Modal from './Modal';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: '1',
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px'
  },
  boton: {
    zIndex: '1',
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px'
  },
  section: {
    // backgroundImage: `url(${(ImagenBG)})`,
    zIndex: '1',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100vh',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '-20px',
    marginBottom: '3.3em',
    paddingTop: '50px'
  },
  container: {
    zIndex: '1',
    paddingTop: '5rem',
    padding: '2rem',
    //backdropFilter: 'blur(5px)',
    //backgroundColor: 'rgba(0,0,0,0.4)',
    margin: '2rem',
    borderRadius: '20px',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '0',
      paddingRight: '0',
      marginLeft: '1rem',
      marginRight: '0'
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      marginLeft: '2rem',
      marginRight: '2rem'
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      marginLeft: '2rem',
      marginRight: '2rem'
    }
  },
  titulo: {
    zIndex: '1',
    fontFamily: 'HelveticaBoldP',
    color: '#fff',
    marginBottom: '0.5rem',
    fontSize: '100px',
    [theme.breakpoints.up('xs')]: {
      fontSize: '70px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '100px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '100px'
    }
  },
  descripcion: {
    zIndex: '1',
    fontFamily: 'HelveticaBoldP',
    color: theme.palette.primary.main,
    fontSize: '100px',
    display: 'flex',
    marginTop: '-7%',
    [theme.breakpoints.up('xs')]: {
      fontSize: '70px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '100px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '100px'
    }
  },
  botonS: {
    marginTop: '10vh',
    [theme.breakpoints.up('xs')]: {
      marginTop: '5vh'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '15vh'
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '18vh'
    }
  }
}));

const HeroAliran = ({ Imagen, Titulo, Descripcion, BotonTexto, variant = 'img', sxB }) => {
  const classes = useStyles();
  const ruta = useLocation().pathname;
  //const route = ruta === '/contact-us' ? 'https://wa.me' : '/contact-us';
  const [st, setST] = useState(false);
  const [videoD, setVideoD] = useState(document.getElementById('heroV'));
  const { vid, setVid } = useVid();
  useEffect(() => {
    setST(variant !== 'video');
  }, [variant]);
  useEffect(() => {
    setVideoD(document.getElementById('heroV'));
    if (vid) {
      if (videoD !== null) {
        videoD.volume = 0;
        videoD.currentTime = 9.045333;
      }
      setST(true);
    }
  }, [vid, videoD]);
  return (
    <>
      {variant === 'video' ? (
        <VideoHero
          videop={Imagen}
          onEnded={() => {
            setST(true);
            setVid(true);
          }}
        />
      ) : null}
      <div
        className={classes.section}
        style={variant === 'img' ? { backgroundImage: `url(${Imagen})` } : { backgroundColor: 'transparent' }}
      >
        <Box component="div" className={classes.container} sx={{ paddingTop: st ? '0%' : '25% !important' }}>
          {st ? (
            <>
              <Typography variant="h1" className={classes.titulo}>
                {Titulo}
              </Typography>
              <Typography variant="h2" className={classes.descripcion}>
                {Descripcion}
              </Typography>
            </>
          ) : null}
          {ruta === '/' ? (
            <FormAltaCredito
              textbutton={BotonTexto}
              fromstart={true}
              sx={{ fontSize: '20px', ...sxB }}
              className={classes.botonS}
            />
          ) : null}
        </Box>
      </div>
    </>
  );
};

export default HeroAliran;

// const HeroAliran = () => {
//   const style = {
//     display: "inline-block",
//     backgroundColor: "orange",
//     padding: 8,
//     marginTop: '1hv',
//   }
//   return (<>
//     <FMBoton text="BTN Antes" />
//     <Lista />
//     <FMBoton text="Botón despues" />
//     {/* <Modal /> */}

//     <FMBoton />
//     <Button />

//   </>
//   );
// };

// export default HeroAliran;
