import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import valoresimg from 'src/components/Images/aliranvalores.svg';

const Styles = makeStyles((theme) => ({
  root: {
    marginTop: '4%',
    marginBottom: '4%'
  },
  title: {
    fontFamily: 'HelveticaBoldP',
    color: theme.palette.secondary.main,
    textAlign: 'center'
  },
  img: {
    width: 'auto'
  },
  imgcontainer: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    alignContent: 'center'
  }
}));

function AboutValores() {
  const classes = Styles();
  return (
    <>
      <Box component="div" className={classes.root}>
        <Typography variant="h3" className={classes.title}>
          Valores
        </Typography>
        <Box component="div" className={classes.imgcontainer}>
          <Box component="img" src={valoresimg} className={classes.img} />
        </Box>
      </Box>
    </>
  );
}

export default AboutValores;
