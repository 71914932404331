import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const Styles = makeStyles((theme) => ({
  video: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    zIndex: '-1'
  }
}));

function VideoHero({ videop, onEnded }) {
  //estilos
  const classes = Styles();
  useEffect(() => {
    const videoh = document.getElementById('heroV');
    videoh.volume = 0;
  });

  return <video src={videop} className={classes.video} autoPlay={true} id="heroV" preload={true} onEnded={onEnded} />;
}

export default VideoHero;
