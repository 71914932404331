import React from 'react';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { ProductPresent } from 'src/components/_external-pages/products';
// components
import Page from '../components/Page';
//imgs
import prod3 from 'src/components/Images/producto3.jpg';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(11),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11)
  }
}));

// ----------------------------------------------------------------------

export default function CreditoPrendario() {
  const CP = {
    title: 'Crédito Prendario',
    subtitle: 'Tu garantía rinde',
    description: (
      <>
        Con éste producto, proporcionamos flujo de efectivo sin la necesidad de un fideicomiso, con un contrato simple y
        tomando en cuenta como prenda, vehículos o relojes.
      </>
    ),
    img: prod3
  };

  return (
    <>
      <RootStyle title="Aliran | Credito Prendario">
        <ProductPresent title={CP.title} subtitle={CP.subtitle} description={CP.description} img={CP.img} index={0} />
      </RootStyle>
    </>
  );
}
