import React from 'react';
import { Box, Grid, Typography, Icon, CardHeader, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const Styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  img: {
    borderRadius: '5px'
  }
}));

Icon.propTypes = {
  info: PropTypes.shape({
    tittle: PropTypes.string.isRequired,
    subtittle: PropTypes.string.isRequired,
    des: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  }).isRequired,
  number: PropTypes.number
};

function Solucion({ info, number = 0 }) {
  //estilos
  const classes = Styles();
  //metodos
  const imgV = () => {
    return <Box component="img" src={info.img} className={classes.img} />;
  };
  const infoC = () => {
    return (
      <>
        <Box component="div">
          <CardHeader
            title={<Typography variant="h2">{info.tittle}</Typography>}
            subheader={<Typography variant="h3">{info.subtittle}</Typography>}
          />
          <CardContent>
            <Box component="div">{info.des}</Box>
            <Box component="div">
              <Button variant="contained" href={info.link} target="_blank">
                saber mas
              </Button>
            </Box>
          </CardContent>
        </Box>
      </>
    );
  };
  return (
    <>
      <Box component="div" className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            {number % 2 === 0 ? infoC() : imgV()}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            {number % 2 !== 0 ? infoC() : imgV()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Solucion;
