import React from 'react';
import { Helmet } from 'react-helmet-async';

function AvisoPrivacidadSEO() {
  return (
    <Helmet>
      <title>Aliran | Aviso de Privacidad</title>
      <meta name="keywords" content="creditos, aliran, aviso, privacidad" />
      <meta name="description" content="Aliran - Aviso de Privacidad " />

      <meta itemprop="name" content="Aliran" />
      <meta itemprop="description" content="Aliran - Aviso de privacidad " />
      <meta itemprop="image" content="" />

      <meta property="og:url" content="https://aliran.com.mx/aviso-privacidad" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Aliran" />
      <meta property="og:description" content="Aliran - Aviso de privacidad " />
      <meta property="og:image" content="" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Aliran" />
      <meta name="twitter:description" content="Aliran - Aviso de privacidad " />
      <meta name="twitter:image" content="" />
    </Helmet>
  );
}

export default AvisoPrivacidadSEO;
