import React from 'react';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const Styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  title: {
    fontFamily: 'HelveticaBoldP',
    color: theme.palette.secondary.main
  },
  subtitle: {
    color: '#6D6F71'
  },
  parraf: {
    color: '#6D6F71',
    textAlign: 'justify',
    textJustify: 'inter-word'
  }
}));

ProductPresent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  link: PropTypes.string
};

function ProductPresent({ title, subtitle, description, img, index, link }) {
  //estilos
  const classes = Styles();
  //subcomponents
  const imgC = () => (
    <Grid item xs={12} md={6} lg={6}>
      <Box component="img" src={img} sx={{ width: '100%', height: 'auto' }} />
    </Grid>
  );
  const contentC = () => (
    <Grid item xs={12} md={6} lg={6}>
      <Box component="p" sx={{ borderBottom: '4px solid #F36D21', margin: '0' }}>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
      </Box>
      <Box component="p">
        <Typography variant="h3" className={classes.subtitle}>
          {subtitle}
        </Typography>
      </Box>
      <Box component="p" sx={{ marginBottom: 2 }}>
        <Typography variant="h6" className={classes.parraf}>
          {description}
        </Typography>
      </Box>
      {Boolean(link) ? (
        <Button variant="contained" href={link}>
          Saber más...
        </Button>
      ) : null}
    </Grid>
  );
  return (
    <>
      <Box component="div" className={classes.root}>
        <Grid container spacing={2}>
          {index % 2 !== 0 ? imgC() : contentC()}
          {index % 2 === 0 ? imgC() : contentC()}
        </Grid>
      </Box>
    </>
  );
}

export default ProductPresent;
